import React from "react"
import HeadlessCTA from "../components/headlessCTA"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tag from "../components/tag"
import logoDoodad from "../images/logo-doodad.png"
import CTA from "../components/cta"
import BenefitCard from "../components/BenefitCard"
import FeatureCard from "../components/FeatureCard"
import UseCases from "../components/UseCases"
import { IconMessage2, IconSortAscending, IconUsers } from "@tabler/icons"

import forCampaignManagers from "../images/use-cases/personas/audience-manager.png"
import forCMOs from "../images/use-cases/personas/cmo.png"
import forStrategic from "../images/use-cases/personas/strategic-marketers.png"

import PersonaCard from "../components/PersonaCard"

import HeroImage from "../images/use-cases/marketing.png"
import Feature1 from "../images/use-cases/marketing 1.png"
import Feature2 from "../images/use-cases/marketing 2.png"
import Feature3 from "../images/use-cases/marketing 3.png"

const MarketingLanding = () => {
  return (
    <Layout variant="useCase">
      <SEO
        title="Optimise your marketing with 10x more insight"
        description="Increase campaign effectiveness, maximise ad spend and take advantage of shifting market conditions by automating research on all your data in one place."
      />
      <div className="max-w-screen overflow-x-hidden relative pb-6">
        <div className="container mx-auto px-4 sm:px-6 mb-12 xl:mb-32">
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-24 py-24 items-center">
            <div>
              <Tag>Tactic for Marketing</Tag>
              <h1
                className="font-extrabold text-white text-5xl md:text-7xl mt-12 mb-4"
                style={{
                  mixBlendMode: "overlay",
                  fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
                }}
              >
                Optimise your marketing with 10x more insight
              </h1>

              <h2 className="text-white text-xl mb-8">
                Increase campaign effectiveness, maximise ad spend and take
                advantage of shifting market conditions by automating research
                on all your data in one place.
              </h2>

              <HeadlessCTA />
            </div>
            <img
              src={HeroImage}
              className="xl:absolute xl:top-24 xl:left-1/2 shadow-violet-400/50 shadow-2xl rounded-lg"
              style={{ maxHeight: "650px" }}
            />
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto mt-12 mb-32 rounded-lg shadow-xl shadow-lilac-15">
        <iframe
          src="https://www.loom.com/embed/d0b26c0465884a40a75f5410776e5d56"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          className="w-full aspect-[16/10] rounded-lg border border-zinc-600"
        ></iframe>
      </div>

      <div className="flex gap-4 items-center">
        <div className="h-px flex-1 bg-salmon"></div>
        <div className="rounded-full border border-salmon text-salmon py-1 px-4 leading-none">
          Problems we solve for Marketing &darr;
        </div>
        <div className="h-px flex-1 bg-salmon"></div>
      </div>

      <img
        src={logoDoodad}
        className="absolute mt-36"
        style={{ maxHeight: "650px" }}
      />

      <div className="container p-4 md:p-24 mx-auto flex flex-col lg:flex-row gap-12 text-white my-24 bg-white/10 rounded-xl backdrop-blur-lg border border-white/20">
        <h2 className="font-bold text-4xl md:text-6xl flex-1 leading-tight z-10">
          Meeting quota is hard when you don't know who to target
        </h2>
        <div className="text-xl flex-1 z-10 md:pt-48">
          <p className="mb-4">
            Every consumer today expects instant, personalised experiences.
            Enterprise buyers are no different. To make things worse, B2B buying
            is becoming more complex, and you need more insight than ever to
            perfect your targeting and messaging.
          </p>
          <p className="text-lilac">
            Personalise at speed and scale with automated research that delivers
            relevant insights for any market, segment and account. Spot trends
            and new pain points on demand. Target them with personalised
            campaigns faster. And hit your pipeline goals quarter after quarter.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6">
        <div className="flex flex-col gap-4 lg:text-center max-w-2xl mx-auto mb-12">
          <h2 className="font-bold text-4xl md:text-5xl flex-1 leading-tight text-white z-10">
            We can fill in the blanks
          </h2>
          <p className="text-medium-grey text-xl">
            You could be so much more effective with clear, comparative views of
            your account data. Blanket campaigns don't work, and are bad for
            your brand - you don't want prospects annoyed by bad fit messaging.
            Level up your GTM with Tactic.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row gap-2">
          <BenefitCard
            icon={
              <IconSortAscending size={48} stroke={1.5} className="text-blue" />
            }
            heading="Find exact targets to optimise ad spend"
            body="Research limitless insights on any company or person to ensure that every ad dollar you spend is targeting an ideal customer."
          />
          <BenefitCard
            icon={<IconMessage2 size={48} stroke={1.5} className="text-blue" />}
            heading="Increase engagement with personalisation"
            body="Get unparalleled intelligence on all the criteria for your ideal customer profile, no matter how niche. Tactic enables personalised and relevant messaging for conversations that convert. "
          />
          <BenefitCard
            icon={<IconUsers size={48} stroke={1.5} className="text-blue" />}
            heading="Relevant. Relatable. Real time."
            body="Tactic projects are quick and agile. Jump on new opportunities quickly, searching for the contacts and details you need to make a move, announcement or real-time marketing campaign."
          />
        </div>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6 my-48">
        <div className="flex flex-col gap-4 lg:text-center max-w-2xl mx-auto">
          <h2 className="font-bold text-5xl flex-1 leading-tight text-white">
            Tactic is for
          </h2>
        </div>
        <div className="flex flex-col lg:flex-row gap-24 mx-auto">
          <PersonaCard
            image={forStrategic}
            jobTitle="Strategic Marketing"
            quote1="“I spend large amounts of money with ABM research agencies.”"
            quote2="“It takes me way too long to launch a new campaign.”"
          />
          <PersonaCard
            image={forCMOs}
            jobTitle="Chief Marketing Officers"
            quote1="“ABM is a strategic priority, but we lack the right data to execute.”"
            quote2="“I need more pipeline to get us to the next growth milestone.”"
          />
          <PersonaCard
            image={forCampaignManagers}
            jobTitle="Campaign/audience managers"
            quote1="“I don't have the right data to segment my campaigns.”"
            quote2="“We are wasting ad spend on accounts that will never close.”"
          />
        </div>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6">
        <h2 className="text-white text-5xl font-bold text-center">
          The best marketing teams target with precision.
        </h2>
        <div className="max-w-2xl text-dark-grey text-xl text-center mb-12 mx-auto">
          Tactic is built for every member of your marketing team to research,
          iterate and engage every customer in a relevant way.
        </div>
        <div className="flex flex-col gap-9">
          <FeatureCard
            heading="Unify data from all sources"
            body="You can import account lists from Salesforce with metadata intact, as well as from spreadsheets. You can research your internal data in parallel with any purchased and generated datasets."
            direction="left"
            image={Feature1}
          />
          <FeatureCard
            heading="Find real, addressable leads"
            body="You can use Tactic to automatically research your data points in natural language, to gather data on your ideal customer profile."
            direction="right"
            image={Feature2}
          />
          <FeatureCard
            heading="Export results in the format you need"
            body="You can use filters and sorts to extrapolate your best results into reports of various formats, ready to integrate with your CRM or use with social and campaign-building apps."
            direction="left"
            image={Feature3}
          />
        </div>
      </div>
      <UseCases hideSection="marketing" />
      <CTA />
    </Layout>
  )
}

export default MarketingLanding
